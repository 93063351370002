@tailwind base;
@tailwind components;
@tailwind utilities;

img, video {
    height: inherit;
    max-width: inherit;
}

.react-daterange-picker .react-daterange-picker__wrapper {
    color: #4A4A4A;
    border: none;
}

.react-daterange-picker--disabled {
    background-color: transparent !important;
}

.react-daterange-picker__calendar {
    z-index: 30 !important;
}

#redsys_form #btn_submit {
    visibility: hidden;
    height: 0;
}

.lazy-load-image-background img {
    min-width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}